import {focusElement, hookToAttributeSelector, loadMadeFor, scrollElementIntoView} from '@wix/panda-js-utils'
import {SlotsPlaceholder} from '@wix/widget-plugins-ooi'
import {BI_ORIGINS, ExperimentNames} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useExperiments} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React, {useEffect} from 'react'
import {SLOT_EVENT_DETAILS_CONTENT} from '../../../../../commons/constants/slots'
import {useSettings} from '../../../hooks/settings'
import {useVisibilityStyles} from '../../../hooks/use-visibility-styles'
import {settingsParams} from '../../../settingsParams'
import {FormEntry, SeatingPlanModal} from '../chunks'
import cs from '../classnames.scss'
import {Members} from '../members'
import {SocialShareWithTitle} from '../social-share/with-title'
import {Subtitle} from '../subtitle'
import {AboutSection} from './about-section'
import alignmentClasses from './alignment/alignment.scss'
import s from './event-details.scss'
import {FullDate} from './full-date'
import {FullLocation} from './full-location'
import {GroupActivity} from './group-activity'
import {Header} from './header'
import {EventDetailsProps} from './interfaces'
import {Map} from './map'
import {MembershipOffers} from './membership-offers'
import {RecurringDatesButton} from './recurring-dates-button'
import {RecurringDatesCount} from './recurring-dates-count'
import {Schedule} from './schedule'
import {SecondaryRsvpButton} from './secondary-rsvp-button'
import {DesktopTicketsPicker} from './tickets-picker-new/lazy-tickets-picker'

export const EventDetails = ({
  eventTitle,
  ticketedEvent,
  membersVisible,
  mapVisible,
  membersDividerVisible,
  aboutSectionVisible,
  showPaidPlans,
  t,
  hasTickets,
  scheduleVisible,
  detailsPageLoaded,
  handleRSVP,
  moreDatesVisible,
  moreDatesButtonVisible,
  groupVisible,
  groupsDividerVisible,
  withSeatingPlan,
  openSeatingPlan,
}: EventDetailsProps) => {
  const {experiments} = useExperiments()
  const {
    allBreakpoints: {isMembersVisible, isGroupVisible, isMembershipsVisible},
  } = useVisibilityStyles()

  useEffect(() => {
    if (withSeatingPlan) {
      loadMadeFor()
    }
  }, [withSeatingPlan])

  useEffect(() => {
    detailsPageLoaded()
    focusElement({selector: hookToAttributeSelector(DH.eventDetails), preventScroll: true})
  }, [])

  const slotsEnabled = experiments.enabled(ExperimentNames.EventDetailsSlots)

  const handleRegistrationButtonClick = () => {
    if (withSeatingPlan) {
      SeatingPlanModal.preload()
      openSeatingPlan()
    } else {
      if (ticketedEvent) {
        return handleTicketedEventRegistration()
      } else {
        FormEntry.preload()
        return handleRSVP()
      }
    }
  }

  const handleTicketedEventRegistration = () => {
    if (showPaidPlans && isMembershipsVisible()) {
      return scrollToMembershipOffers()
    } else {
      return scrollToTicketsPicker()
    }
  }

  const settings = useSettings()
  const timeAndLocationTitleText = settings.get(settingsParams.timeAndLocationTitleText)
  const guestsTitleText = settings.get(settingsParams.guestsTitleText)

  return (
    <section data-hook={DH.eventDetails} aria-label={eventTitle}>
      <div className={classNames(s.background, cs.evBackgroundColor)} />
      <Header onClickRegistrationButton={handleRegistrationButtonClick} />
      <div className={s.container} data-hook={DH.eventDetailsContent}>
        <div className={classNames(s.content, alignmentClasses.contentAlignment)}>
          <Subtitle dataHook={DH.subtitleTimeAndLocation} text={timeAndLocationTitleText} />
          {moreDatesVisible && <RecurringDatesCount />}
          <div className={classNames(s.timeAndLocationContainer)}>
            <div className={classNames(s.timeAndLocation, cs.evTextFont, cs.evTextColor)}>
              <FullDate />
              <FullLocation />
            </div>
            {moreDatesButtonVisible && <RecurringDatesButton className={s.recurringDates} />}
          </div>
          {moreDatesButtonVisible && <Divider />}
          {membersVisible && isMembersVisible() ? (
            <div className={s.membersContainerVisibility}>
              <Subtitle dataHook={DH.MEMBERS_TITLE} text={guestsTitleText} compact />
              <div className={s.membersContainer}>
                <Members />
              </div>
              {membersDividerVisible && <Divider className={s.membersDivider} />}
            </div>
          ) : (
            <div />
          )}
          {aboutSectionVisible && <AboutSection compact={membersVisible} />}
          {groupsDividerVisible && <Divider />}
          {groupVisible && isGroupVisible() ? (
            <GroupActivity className={classNames(s.group, s.groupActivitySpacing)} />
          ) : null}
          {scheduleVisible && <Schedule t={t} />}
          {slotsEnabled ? (
            <div className={s.slotDetailsContainer}>
              <SlotsPlaceholder slotId={SLOT_EVENT_DETAILS_CONTENT} />
            </div>
          ) : null}
          <SecondaryRsvpButton onClick={handleRegistrationButtonClick} />
          <div>{showPaidPlans && isMembershipsVisible() ? <MembershipOffers /> : null}</div>
          {hasTickets && <DesktopTicketsPicker />}
        </div>
        {mapVisible && (
          <div className={s.mapSpacing}>
            <Map />
          </div>
        )}
        <div className={classNames(s.socialShare, s.content, s.sectionSpacing, alignmentClasses.contentAlignment)}>
          <SocialShareWithTitle page={BI_ORIGINS.EVENT_DETAILS} t={t} />
        </div>
        <div className={s.safariMarginFix} />
      </div>
    </section>
  )
}

interface DividerProps {
  className?: string
}

const Divider = ({className}: DividerProps) => (
  <div className={classNames(s.divider, cs.evTicketDividerColor, cs.evTicketDividerSize, className)} />
)

const scrollToTicketsPicker = () => {
  scrollElementIntoView(hookToAttributeSelector(DH.TICKETS_PICKER), {block: 'start', behavior: 'smooth'})
}

const scrollToMembershipOffers = () => {
  scrollElementIntoView(hookToAttributeSelector(DH.MEMBERSHIP_OFFERS), {block: 'start', behavior: 'smooth'})
}
