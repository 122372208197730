import classNames from 'classnames'
import React from 'react'
import {Location} from 'wix-ui-icons-common'
import c from '../../../../classnames.scss'
import {Tags} from '../../../../styled-components/tags'
import s from '../list.scss'
import {DescriptionProps} from './index'

export const Description = ({name, stageName, tags, t}: DescriptionProps) => (
  <div>
    <h3 className={classNames(s.scheduleTitle, c.scheduleTitleFont, c.scheduleTitleColor)}>{name}</h3>
    {stageName ? (
      <div className={classNames(s.location, c.scheduleLocationFont, c.scheduleLocationColor)}>
        <Location aria-label={t('schedule_location_aria')} /> {stageName}
      </div>
    ) : null}
    {tags ? (
      <Tags
        className={s.tags}
        items={tags?.map(tag => ({
          title: tag,
          value: tag,
        }))}
      />
    ) : null}
  </div>
)
