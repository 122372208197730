import {addQueryParams} from '@wix/panda-js-utils'
import {TFunction} from '@wix/yoshi-flow-editor'
import {State} from '../../components/widget/Widget/types/state'
import {CommonState, Multilingual} from '../types/state'
import {isMultidayEventsCalendarEnabled} from './experiments'

export const getEventUrl = (pageUrl: string = '', event: wix.events.Event, multilingual: Multilingual) => {
  const url = `${pageUrl}/${event.slug}`

  if (multilingual.isEnabled) {
    const lang = multilingual.currentLanguage
    return addQueryParams(url, {lang})
  }

  return url
}

export const getEventDateInformation = (state: CommonState, id: string) => state.dates.events[id]

export const isMultiDay = (state: State, event: wix.events.Event) => {
  const events = state.dates.events
  const eventId = event.id
  const multiDay = events[eventId].multiDayInfo?.duration > 1

  return isMultidayEventsCalendarEnabled(state) && multiDay
}

export const getMultiDayText = (state: State, id: string, currentDate: string, t: TFunction) => {
  const event = state.dates.events[id]
  if (!event.multiDayInfo) {
    return ''
  }

  const {duration, ...eventDates} = event.multiDayInfo
  const currentDay = eventDates[currentDate]

  return t('layout.calendar.monthly.events.multiDayTitle', {count: currentDay, total: duration})
}
